import { FC } from 'react'
import { SvgProps } from '@/components/atoms/svg/svgProps'
const ShowAll: FC<SvgProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 154 154"
      width={width || 18}
      height={height || 18}
    >
      <g
        fill="none"
        stroke={color || '#000'}
        strokeWidth={'4px'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      >
        <g id="showall">
          <g>
            <circle cx="15.17" cy="15.17" r="13.17" />
            <circle cx="15.17" cy="56.39" r="13.17" />
            <circle cx="15.17" cy="97.61" r="13.17" />
            <circle cx="15.17" cy="138.83" r="13.17" />
          </g>
          <g>
            <circle cx="56.39" cy="15.17" r="13.17" />
            <circle cx="56.39" cy="56.39" r="13.17" />
            <circle cx="56.39" cy="97.61" r="13.17" />
            <circle cx="56.39" cy="138.83" r="13.17" />
          </g>
          <g>
            <circle cx="97.61" cy="15.17" r="13.17" />
            <circle cx="97.61" cy="56.39" r="13.17" />
            <circle cx="97.61" cy="97.61" r="13.17" />
            <circle cx="97.61" cy="138.83" r="13.17" />
          </g>
          <g>
            <circle cx="138.83" cy="15.17" r="13.17" />
            <circle cx="138.83" cy="56.39" r="13.17" />
            <circle cx="138.83" cy="97.61" r="13.17" />
            <circle cx="138.83" cy="138.83" r="13.17" />
          </g>
        </g>
      </g>
    </svg>
  )
}
export default ShowAll
